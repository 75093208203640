/* eslint-disable react/destructuring-assignment */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Alert,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Modal from 'src/components/shared/Modal';
import {
  ModerationCategory,
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectAdminStatus,
  ObjectUserStatus,
} from 'src/lib/types/objectModeration';

import { capitalCase } from 'change-case';
import Button from 'src/components/shared/Buttons/CustomButton';
import Tooltip from 'src/components/shared/Tooltip';
import { reviewStatusesByHumanReview } from '../../utils/objectStatusAndActionables';
import { tooltipTextByHumanReview } from '../../utils/statusAndActionablesTooltipsText';
import StatusAndActionablesCard from '../StatusAndActionablesCard';
import { UseReviewFormProps } from './types';
import { useReviewForm } from './useReviewForm';

const reviewStatusLabel: Record<OBJECT_MODERATION_HUMAN_REVIEW, string> = {
  [OBJECT_MODERATION_HUMAN_REVIEW.FULL_APPROVAL]: 'Full Approval',
  [OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL]: 'Limited Approval',
  [OBJECT_MODERATION_HUMAN_REVIEW.REJECTION]: 'Rejection',
  [OBJECT_MODERATION_HUMAN_REVIEW.NOT_SET]: 'Not Set',
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ReviewForm(props: UseReviewFormProps) {
  const {
    isOpen,
    closeModal,
    handleSubmit,
    control,
    onSubmit,
    formState: { isSubmitting },
    currentHumanReview,
    setValue,
    getFieldState,
  } = useReviewForm(props);

  const isRejection =
    currentHumanReview === OBJECT_MODERATION_HUMAN_REVIEW.REJECTION;

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Card
        sx={{
          width: '800px',
        }}
      >
        <CardHeader title="Review Object" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography variant="subtitle1">Human Review </Typography>
              <Controller
                name="humanReview"
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    {Object.values(OBJECT_MODERATION_HUMAN_REVIEW).map(
                      (type) => (
                        <Stack
                          key={type}
                          direction="row"
                          spacing={1}
                          alignItems={'center'}
                        >
                          <FormControlLabel
                            key={type}
                            value={type}
                            control={<Radio />}
                            label={reviewStatusLabel[type]}
                          />
                          <Tooltip title={[tooltipTextByHumanReview[type]]} />
                        </Stack>
                      )
                    )}
                  </RadioGroup>
                )}
              />

              {getFieldState(`humanReview`).error?.message && (
                <Alert severity="error">
                  {getFieldState(`humanReview`).error?.message}
                </Alert>
              )}

              <Stack spacing={2}>
                <Typography variant="subtitle1">
                  Status and Actionables Preview
                </Typography>

                <StatusAndActionablesCard
                  reviewStatus={
                    (currentHumanReview &&
                      reviewStatusesByHumanReview[currentHumanReview]) ??
                    props.reviewStatus ??
                    OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL
                  }
                  adminStatus={props.adminStatus ?? ObjectAdminStatus.ACTIVE}
                  userStatus={props.userStatus ?? ObjectUserStatus.PUBLIC}
                />
              </Stack>

              {isRejection && (
                <>
                  <Typography variant="subtitle1">Rejection Reasons</Typography>
                  <Controller
                    name="humanReviewRejectionReason"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        id="checkboxes-rejection-reason"
                        options={Object.values(ModerationCategory)}
                        disableCloseOnSelect
                        renderOption={(_props, option, { selected }) => (
                          <li {..._props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {capitalCase(option)}
                          </li>
                        )}
                        style={{ width: '100%' }}
                        onChange={(e, value) => {
                          setValue(
                            'humanReviewRejectionReason',
                            value.sort().join(',')
                          );
                        }}
                        defaultValue={[]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Rejection Reasons"
                            placeholder="Select Reasons"
                            {...field}
                          />
                        )}
                      />
                    )}
                  />
                  {getFieldState(`humanReviewRejectionReason`).error
                    ?.message && (
                    <Alert severity="error">
                      {
                        getFieldState(`humanReviewRejectionReason`).error
                          ?.message
                      }
                    </Alert>
                  )}
                </>
              )}

              <Stack spacing={2}>
                <Typography variant="subtitle1">
                  Internal Justification
                </Typography>
                <Controller
                  name="internalJustification"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Internal Justification"
                      fullWidth
                      {...field}
                      variant="outlined"
                      multiline
                      maxRows={5}
                      minRows={4}
                    />
                  )}
                />
              </Stack>
              {isRejection && (
                <Stack spacing={2}>
                  <Typography variant="subtitle1">
                    Review Note (Optional)
                  </Typography>
                  <Controller
                    name="reviewNote"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label="Review Note (Optional)"
                        fullWidth
                        {...field}
                        variant="outlined"
                        multiline
                        maxRows={5}
                        minRows={4}
                      />
                    )}
                  />
                </Stack>
              )}

              {/*
              isRejection && (
                <Flex
                  sx={{
                    width: '50%',
                  }}
                >
                  Block User
                  <Controller
                    name="blockUser"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </Flex>
              )
              */}

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
                size="large"
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default ReviewForm;
