import { Add, Download } from '@mui/icons-material';
import { Button, Card, CardContent, Container, Tab, Tabs } from '@mui/material';
import { MODERATED_OBJECT_STATUS_TABS } from 'src/components/modules/Review/utils/utils';
import Flex from 'src/components/shared/Flex';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import GamesTable from '../../components/GamesTable/GamesTable';
import useGames from './useView';

const STATUS_TABS = MODERATED_OBJECT_STATUS_TABS;

export default function Games() {
  const {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleOpenDeleteModal,
    handleOpenUpdateModal,
    handleChangeGameStatus,
    handleDownloadActiveGamesCSV,
    currentTab,
    onChangeTab,
  } = useGames();
  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Games"
        links={[{ name: 'Games' }]}
        action={
          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenCreateModal}
            startIcon={<Add />}
          >
            Add Game
          </Button>
        }
      />

      <Flex alignItems={'end'} justifyContent={'end'} mb="15px">
        <Button
          onClick={handleDownloadActiveGamesCSV}
          variant="contained"
          color="secondary"
          startIcon={<Download />}
        >
          Generate CSV
        </Button>
      </Flex>

      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <GamesTable
            data={data?.rows || []}
            totalCount={data?.count || 0}
            isLoading={isLoading}
            params={params}
            handleOpenUpdateModal={handleOpenUpdateModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            onChangeGameStatus={handleChangeGameStatus}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
