import { getDefaultObjectLinksOptions } from 'src/components/shared/Form/useObjectLinkSearch';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import GameForm from '../../components/Forms/GameForm';
import useUpdateGameView from './useView';

interface UpdateGameViewProps {
  game: AdminListGamesRow;
}

export default function UpdateGameView({
  game,
}: UpdateGameViewProps): JSX.Element {
  const { handleUpdateGame } = useUpdateGameView(game.uuid);

  const version = game.versions?.[0];

  return (
    <GameForm
      defaultValues={{
        game: {
          uuid: game.uuid,
          name: game.name,
          description: game.description,
          gamePublisher: game.publisher?.name ?? '',
          gameCategoryUuid:
            game.categories && game.categories.length > 0
              ? game.categories[0].uuid
              : '',
          gameTagsNames: game.tagsStrings,
          developerName: game.developerName,

          isMobile: game.isMobile,
          orientation: game.orientation,
          publishRightsType: game.publishRightsType,
          secondaryLink: game.secondaryLink,
          hasAgreedTerms: game.hasAgreedTerms,
        },
        version: {
          version: version?.version ?? '',
          url: version?.url ?? '',
          imageUrl: version?.imageUrl ?? '',
          ageRestriction: version?.ageRestriction ?? 0,
          gameModesUuids: version?.modes?.map((mode) => mode.uuid) ?? [],

          additionalComments: version?.additionalComments ?? '',
          hasAds: version?.hasAds,
        },

        objectLinks: getDefaultObjectLinksOptions(game.objectLinks),
        objectLinkSearch: '',
      }}
      onSubmit={handleUpdateGame}
      title="Update Game"
      game={game}
    />
  );
}
