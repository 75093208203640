import {
  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED,
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectActionablesStatus,
  ObjectAdminStatus,
  ObjectUserStatus,
  ObjectVisibility,
  ObjectVisibleStatus,
  StatusesAndActionablesOnObjectParams,
} from 'src/lib/types/objectModeration';

export const objectStatusAndActionables: Record<
  string,
  StatusesAndActionablesOnObjectParams
> = {
  [`${OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      visibility: ObjectVisibility.PRIVATE,
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      visibility: ObjectVisibility.NOT_VISIBLE,
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      visibility: ObjectVisibility.PRIVATE,
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      visibility: ObjectVisibility.NOT_VISIBLE,
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW,
      visibleStatus: ObjectVisibleStatus.IN_REVIEW,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.PRIVATE,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.NOT_VISIBLE,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.LIMITED,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED,
      visibility: ObjectVisibility.NOT_VISIBLE,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.REJECTION}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.REJECTION}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.REJECTION}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.HIDDEN,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.REJECTION}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
      visibleStatus: ObjectVisibleStatus.NEEDS_REVISION,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.OFF,
      social: ObjectActionablesStatus.OFF,
      transfer: ObjectActionablesStatus.OFF,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.LIMITED,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      visibility: ObjectVisibility.PRIVATE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL}_${ObjectUserStatus.PRIVATE}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PRIVATE,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.ACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.EVERYWHERE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
  [`${OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL}_${ObjectUserStatus.PUBLIC}_${ObjectAdminStatus.INACTIVE}`]:
    {
      reviewStatus: OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
      visibleStatus: ObjectVisibleStatus.PUBLIC,
      visibility: ObjectVisibility.NOT_VISIBLE,
      editability: ObjectActionablesStatus.ON,
      monetization: ObjectActionablesStatus.ON,
      social: ObjectActionablesStatus.ON,
      transfer: ObjectActionablesStatus.ON,
      humanAnalysisNeeded: OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE,
    },
};

export function getStatusesAndActionablesByModerationStatus(
  reviewStatus: OBJECT_MODERATION_REVIEW_STATUS,
  userStatus: ObjectUserStatus,
  adminStatus: ObjectAdminStatus
): StatusesAndActionablesOnObjectParams {
  const statusCombinationKey = `${reviewStatus}_${userStatus}_${adminStatus}`;

  return objectStatusAndActionables[statusCombinationKey];
}

export const reviewStatusesByHumanReview: Record<
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_REVIEW_STATUS
> = {
  [OBJECT_MODERATION_HUMAN_REVIEW.FULL_APPROVAL]:
    OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL,
  [OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL]:
    OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL,
  [OBJECT_MODERATION_HUMAN_REVIEW.REJECTION]:
    OBJECT_MODERATION_REVIEW_STATUS.REJECTION,
  [OBJECT_MODERATION_HUMAN_REVIEW.NOT_SET]:
    OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED,
};
