export enum ProfileTabs {
  // General
  ACCOUNT_DETAILS = 'Account Details',
  WALLET = 'Wallet',
  NOTES = 'Notes',

  // Collectibles
  COLLECTIONS = 'Collections',
  ITEMS_CREATED = 'Items Created',
  ITEMS_OWNED = 'Items Owned',
  STATS = 'Stats',
  BIDS = 'Bids',

  // Transactions
  TRANSACTIONS_POOLS_HISTORY = 'Pools History',
  TRANSACTIONS_HISTORY = 'Transactions History',
  SALES = 'Sales',

  // Payout
  PAYOUT_ELIGIBILITY = 'Payout Eligibility',
  PAYOUT_HISTORY = 'Payout History',

  // Purchases
  SUBSCRIPTIONS = 'Subscriptions',
  CONSUMABLES = 'Consumables',

  // Gaming
  GAME_SESSIONS = 'Game Sessions',
  GAME_RATINGS = 'Game Ratings',

  // Clubs
  CLUBS = 'Clubs',

  // Channel
  CHANNELS = 'Channels',

  //
  MODERATION = 'Moderation',
}

export enum DropdownTabs {
  GENERAL = 'General',
  COLLECTIBLES = 'Collectibles',
  TRANSACTIONS = 'Transactions',
  PAYOUT = 'Payout',
  PURCHASES = 'Purchases',
  GAMING = 'Gaming',
  CLUB = 'Club',
  CHANNEL = 'Channel',
}
