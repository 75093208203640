import React, { ReactElement } from 'react';
import { WithPaymentInfo } from 'src/lib/types/PaymentInfo';
import { WithCollections } from 'src/lib/types/collection';
import { WithGlobalWallets } from 'src/lib/types/globalWallet';
import { OBJECT_MODERATION_OBJECT_TYPE } from 'src/lib/types/objectModeration';
import { WithPaymentRequests } from 'src/lib/types/payment';
import { User } from 'src/lib/types/users';
import { WithCryptoWallets } from 'src/lib/types/wallet/cryptoWallet';
import { WithWallet } from 'src/lib/types/wallet/wallets';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';
import { WithDevices } from '../Devices/types/Device';
import PayoutEligibility from '../Payments/Payment/PaymentRequestDetails/PayoutEligibility';
import ObjectModerationTab from '../Review/components/ModerationObjectsTab';
import ActivityPage from './sections/Activity/ActivityTabPage';
import UserChannels from './sections/Channels';
import UserClubs from './sections/Clubs';
import UserConsumables from './sections/Consumables';
import UserGameRatings from './sections/GameRatings';
import UserGameSessions from './sections/GameSessions';
import UserSubscriptions from './sections/Subscriptions';
import { DropdownTabs, ProfileTabs } from './types/ProfileTabs';

const AccountDetails = React.lazy(() => import('./sections/AccountDetails'));
const CreatedCollections = React.lazy(
  () => import('./sections/CreatedCollections')
);
const NftsCreated = React.lazy(() => import('./sections/NftsCreated'));
const PaymentHistory = React.lazy(
  () => import('./sections/PaymentHistory/PaymentHistory')
);
const TransactionPoolHistory = React.lazy(
  () => import('./sections/TransactionPoolHistory/TransactionPoolHistory')
);
const TransactionHistory = React.lazy(
  () => import('./sections/TransactionHistory/TransactionHistory')
);
const Wallet = React.lazy(() => import('./sections/Wallet'));
const NftsCollected = React.lazy(() => import('./sections/NftsCollected'));
const AssociatedNotes = React.lazy(() => import('./sections/AssociatedNotes'));
const OrderBookBids = React.lazy(() => import('./sections/OrderBookBids'));

interface IProfileTabs {
  value: ProfileTabs;
  icon: ReactElement<IconifyProps>;
  component: (
    user: WithGlobalWallets<
      WithDevices<
        WithWallet<
          WithCryptoWallets<
            WithPaymentRequests<WithPaymentInfo<WithCollections<User>>>
          >
        >
      >
    >,
    key: React.Key
  ) => JSX.Element;
}

// the root can have multiple tabs, each tab has a value, an icon and a component
// the root can have no tabs, and be the tab itself
type DropdownProfileTabs =
  | {
      icon?: ReactElement<IconifyProps>;
      value: DropdownTabs;
      children: IProfileTabs[];
    }
  | IProfileTabs;

export const PROFILE_DROPDOWN_TABS: DropdownProfileTabs[] = [
  {
    value: DropdownTabs.GENERAL,
    icon: <Iconify icon="ic:round-account-box" width={20} height={20} />,
    children: [
      {
        value: ProfileTabs.ACCOUNT_DETAILS,
        icon: <Iconify icon="ic:round-account-box" width={20} height={20} />,
        component: (user, key) => <AccountDetails user={user} key={key} />,
      },
      {
        value: ProfileTabs.WALLET,
        icon: <Iconify icon="bxs:wallet-alt" width={20} height={20} />,
        component: (user, key) => <Wallet user={user} key={key} />,
      },
      {
        value: ProfileTabs.NOTES,
        icon: <Iconify icon="ph:note" width={20} height={20} />,
        component: (user, key) => <AssociatedNotes user={user} key={key} />,
      },
    ],
  },
  {
    value: DropdownTabs.GAMING,
    icon: <Iconify icon="bx:bxs-game" width={20} height={20} />,
    children: [
      {
        value: ProfileTabs.GAME_SESSIONS,
        icon: <Iconify icon="bx:bxs-game" width={20} height={20} />,
        component: (user, key) => <UserGameSessions key={key} />,
      },
      {
        value: ProfileTabs.GAME_RATINGS,
        icon: <Iconify icon="bx:bxs-star" width={20} height={20} />,
        component: (user, key) => <UserGameRatings key={key} />,
      },
    ],
  },
  {
    value: ProfileTabs.CLUBS,
    icon: <Iconify icon="bx:bxs-group" width={20} height={20} />,
    component: (user, key) => <UserClubs key={key} />,
  },
  {
    value: ProfileTabs.CHANNELS,
    icon: <Iconify icon="bx:bxs-video" width={20} height={20} />,
    component: (user, key) => <UserChannels key={key} />,
  },
  {
    value: DropdownTabs.COLLECTIBLES,
    icon: <Iconify icon="mdi:chart-timeline-variant" width={20} height={20} />,
    children: [
      {
        value: ProfileTabs.STATS,
        icon: (
          <Iconify icon="mdi:chart-timeline-variant" width={20} height={20} />
        ),
        component: (user, key) => (
          <ActivityPage userUuid={user?.uuid} key={key} />
        ),
      },
      {
        value: ProfileTabs.COLLECTIONS,
        icon: <Iconify icon="bxs:collection" width={20} height={20} />,
        component: (user, key) => <CreatedCollections user={user} key={key} />,
      },
      {
        value: ProfileTabs.ITEMS_CREATED,
        icon: <Iconify icon="mdi:creation" width={20} height={20} />,
        component: (user, key) => <NftsCreated user={user} key={key} />,
      },
      {
        value: ProfileTabs.ITEMS_OWNED,
        icon: <Iconify icon="maki:art-gallery" width={20} height={20} />,
        component: (user, key) => <NftsCollected user={user} key={key} />,
      },
      {
        value: ProfileTabs.BIDS,
        icon: (
          <Iconify
            icon="material-symbols:bid-landscape-outline"
            width={20}
            height={20}
          />
        ),
        component: (user, key) => <OrderBookBids user={user} key={key} />,
      },
    ],
  },
  {
    value: DropdownTabs.TRANSACTIONS,
    icon: <Iconify icon="akar-icons:arrow-repeat" width={20} height={20} />,
    children: [
      {
        value: ProfileTabs.TRANSACTIONS_POOLS_HISTORY,
        icon: <Iconify icon="akar-icons:arrow-repeat" width={20} height={20} />,
        component: (user, key) => (
          <TransactionPoolHistory userUuid={user?.uuid} key={key} />
        ),
      },
      {
        value: ProfileTabs.TRANSACTIONS_HISTORY,
        icon: (
          <Iconify icon="grommet-icons:transaction" width={20} height={20} />
        ),
        component: (user, key) => (
          <TransactionHistory userUuid={user?.uuid} key={key} />
        ),
      },
    ],
  },
  {
    value: DropdownTabs.PAYOUT,
    icon: (
      <Iconify icon="fa6-solid:money-bill-transfer" width={20} height={20} />
    ),
    children: [
      {
        value: ProfileTabs.PAYOUT_ELIGIBILITY,
        icon: (
          <Iconify
            icon="fa6-solid:money-bill-transfer"
            width={20}
            height={20}
          />
        ),
        component: (user, key) => (
          <PayoutEligibility userId={user.uuid} key={key} />
        ),
      },
      {
        value: ProfileTabs.PAYOUT_HISTORY,
        icon: (
          <Iconify
            icon="fa6-solid:money-bill-transfer"
            width={20}
            height={20}
          />
        ),
        component: (user, key) => <PaymentHistory user={user} key={key} />,
      },
    ],
  },
  {
    value: DropdownTabs.PURCHASES,
    icon: <Iconify icon="fluent-mdl2:product" width={20} height={20} />,
    children: [
      {
        value: ProfileTabs.SUBSCRIPTIONS,
        icon: <Iconify icon="fluent-mdl2:product" width={20} height={20} />,
        component: (user, key) => <UserSubscriptions key={key} />,
      },
      {
        value: ProfileTabs.CONSUMABLES,
        icon: <Iconify icon="mdi:food-apple" width={20} height={20} />,
        component: (user, key) => <UserConsumables key={key} />,
      },
    ],
  },
  {
    value: ProfileTabs.MODERATION,
    icon: <Iconify icon="mdi:shield-account" width={20} height={20} />,
    component: (user) => (
      <ObjectModerationTab
        objectType={OBJECT_MODERATION_OBJECT_TYPE.USER}
        objectUuid={user.uuid!}
      />
    ),
  },
];

export const PROFILE_TABS: IProfileTabs[] = extractProfileTabs(
  PROFILE_DROPDOWN_TABS
);

export function extractProfileTabs(
  tabsArray: DropdownProfileTabs[]
): IProfileTabs[] {
  return tabsArray.reduce<IProfileTabs[]>((acc, tab) => {
    if ('children' in tab) {
      return acc.concat(tab.children);
    }
    return acc.concat(tab);
  }, []);
}
